<script setup lang="ts">
  import { MpIcon, MpSpinner } from '@borg/ui';
  import { isUndefined } from '@borg/utils';

  const props = defineProps<{
    /**
     * If provided will override user image from store or project config fallback
     */
    src?: string;
    /**
     * Show empty avatar
     */
    empty?: boolean;
    /**
     * If true, will not show border (this will be ignored when project config fallback is used)
     */
    borderless?: boolean;
    /**
     * Size of the avatar
     */
    size?: 'sm' | 'md' | 'lg' | 'xl';
    /**
     * If true, will show a spinner... used in header when user is google-one-tap logging in
     */
    isLoading?: boolean;
  }>();

  const userStore = useUserStore();

  const computedSrc = computed(() =>
    props.src ? props.src : userStore.user?.image?.url ?? undefined,
  );
  const cBorderless = computed(() => props.borderless || isUndefined(computedSrc.value));
</script>

<template>
  <div
    :class="{
      'avatar': true,
      'avatar--borderless': cBorderless,
      'avatar--loading': isLoading,
      [`avatar--${size}`]: size,
    }"
  >
    <MpSpinner v-if="isLoading" />
    <Illustration
      v-else-if="!computedSrc"
      image="avatar"
      class="avatar__img"
      style="height: 100%"
    />
    <img
      v-else-if="!empty"
      :src="computedSrc"
      class="avatar__img"
      alt="user_avatar"
    />
    <MpIcon
      v-else
      class="avatar__icon"
      name="file_png"
      size="lg"
    />
  </div>
</template>

<style scoped lang="scss">
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2rem;
    min-height: 2rem;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;

    &--borderless {
      outline-color: transparent;
    }

    &--loading {
      outline-color: var(--mp-color-secondary-default);
    }

    &--sm {
      // 40px
      $smSize: 2.5rem;
      min-width: $smSize;
      min-height: $smSize;
      width: $smSize;
      height: $smSize;
    }

    &--md {
      // 80px
      $mdSize: 5rem;
      min-width: $mdSize;
      min-height: $mdSize;
      width: $mdSize;
      height: $mdSize;
    }

    &--lg {
      // 112px
      $lgSize: 7rem;
      min-width: $lgSize;
      min-height: $lgSize;
      width: $lgSize;
      height: $lgSize;
    }

    &--xl {
      // 150px
      $xlSize: 9.375rem;
      min-width: $xlSize;
      min-height: $xlSize;
      width: $xlSize;
      height: $xlSize;
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__icon {
      color: var(--mp-color-grey-400);

      @include dark-mode {
        color: var(--mp-color-grey-200);
      }
    }
  }
</style>
